import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  height: 50px;
  position: fixed;
  padding-bottom: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-top: 25px;
  background: ${({ theme }) => theme.colors.background} fixed;
  button {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    background: none;
  }
`;
