import { createGlobalStyle } from 'styled-components';
import RobotoWoff2 from '../fonts/Roboto/Roboto.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: local('Roboto');
    url(${RobotoWoff2}) format('woff2');
    font-style: normal;
    font-weight: bold;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    outline: none;
    box-shadow: none;
    border: 0;
    text-decoration: none;
    text-align: left;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
    display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  html,
  body {
    margin: 0;
    font-size: 16px;
    background: ${({ theme }) => theme.colors.background} fixed;
  }

  button {
    cursor: pointer;
  }

  h2 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
  }

  .routes {
    flex: 1;
  }

  .header{
    height: 110px;
  }

  .footer{
    height: 75px;
  }

  a,button {
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  }
`;
