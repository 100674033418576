import MotoLogo from 'app/assets/img/Conta Digital Motorola Copy 1.svg';
import { Container } from './styles';

export default function ChatFooter() {
  return (
    <Container
      style={{
        height: `${window.innerHeight - 224}px`,
      }}
    >
      <div>
        <img src={MotoLogo} />
        <h4>Tarifas Conta Digital Motorola</h4>
        <section>
          <p>Taxa de manutenção</p>
          <p>R$ 0,00</p>
        </section>
        <section>
          <p>TED</p>
          <p>R$ 1,99</p>
        </section>
        <section>
          <p>Pix (transferência ou recebimento)</p>
          <p>R$ 0,00</p>
        </section>
        <section>
          <p>Depósito via boleto</p>
          <p>R$ 0,00</p>
        </section>
        <section>
          <p>Emissão do cartão pré-pago</p>
          <p>R$ 0,00</p>
        </section>
        <section>
          <p>
            Saque no cartão pré-pago (valor unitário e apenas para quando sacar)
          </p>
          <p>R$ 5,99</p>
        </section>
        <section>
          <p>Saque no QR code</p>
          <p> R$ 5,99</p>
        </section>
        <h4>Tarifas do cartão pré-pago</h4>
        <section>
          <p>Anuidade</p>
          <p>R$ 0,00</p>
        </section>
        <section>
          <p>Pagamento de contas</p>
          <p>R$ 0,00</p>
        </section>
        <section>
          <p>Emissão 2a via do cartão pré-pago</p>
          <p>R$ 14,99</p>
        </section>
        <h4>Compras e Saques Internacionais com cartão pré-pago</h4>
        <section>
          <p>Dólar referência</p>
          <p>R$ 5,00</p>
        </section>
        <section>
          <p>Emissão 2a via do cartão pré-pago</p>
          <p>R$ 14,99</p>
        </section>
        <section>
          <p>IOF</p>
          <p>6,8%</p>
        </section>
        <section>
          <p>Juros no saque do cartão pré-pago</p>
          <p>0%</p>
        </section>
      </div>
    </Container>
  );
}
