import { getDataStorage, setDataStorage } from 'app/shared/utils/common';
import { useEffect, useState } from 'react';

export default function useThemeParams() {
  const [themeType, setThemeType] = useState(getDataStorage('theme'));

  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!themeType) {
      const theme = searchParams.get('theme');
      setDataStorage('theme', theme);
      setThemeType(theme);
    }
  }, [themeType]);

  return { themeType };
}
