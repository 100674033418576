import { useEffect, useState } from 'react';
import api from 'app/api';

const useAxios = (url, params = '') => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const res = await api.get(url, {
          params,
        });

        let json = await res.data.sort((a, b) => a.id - b.id);

        setData(json);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [url]);

  return { loading, error, data };
};

export default useAxios;
