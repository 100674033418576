import styled from 'styled-components';

export const Loading = styled.div`
  display: block;
  position: fixed;

  img {
    animation: spin 1s linear infinite;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
