import { Loading } from './styles';
import { CENTER_WIDTH, CENTER_HEIGHT } from 'app/core/constants/common';
import loading from 'app/assets/icons/loading.svg';

export default function LoadingSpin() {
  return (
    <Loading
      style={{
        top: CENTER_HEIGHT,
        left: CENTER_WIDTH,
      }}
    >
      <img src={loading} alt="Loading" />
    </Loading>
  );
}
