import { HEIGHT } from 'app/core/constants/common';
import { Container } from 'app/core/styles/App';
import GlobalStyles from 'app/core/styles/global';
import defaultTheme from 'app/core/styles/themes/default';
import history from 'app/history';
import ChatFooter from 'app/shared/components/ChatFooter';
import Header from 'app/shared/components/Header';
import Routes from 'app/shared/components/Routes/routes';
import { AuthProvider } from 'context/AuthContext';
import useThemeParams from 'context/hooks/useParams';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

function App() {
  useThemeParams();

  return (
    <AuthProvider>
      <Router history={history}>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <Container style={HEIGHT} id="container-app">
            <div className="header">
              <Header />
            </div>
            <div className="routes">
              <Routes />
            </div>
            <div className="footer">
              <ChatFooter />
            </div>
          </Container>
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;