import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;

  p {
    color: ${({ theme }) => theme.colors.midEmphasis};
    text-align: left;
    font-size: 14px;
    line-height: 1.8;
    font-family: Roboto;
  }
`;
