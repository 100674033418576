import styled from 'styled-components';

export const Container = styled.header`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding: 45px 0 45px 0;
  background: ${({ theme }) => theme.colors.background} fixed;

  svg {
    margin-left: 22px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.white};
    font-family: Roboto;
    font-weight: 400;
    grid-area: 1 / 2;
    padding: 2px;
  }
`;
