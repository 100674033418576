import { HEIGHT } from 'app/core/constants/common';
import LoadingSpin from 'app/shared/components/LoadinSpin';
import { Context } from 'context/AuthContext';
import { useContext, useEffect } from 'react';

export default function LoginPage() {
  const { handleLogin } = useContext(Context);

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <div style={HEIGHT}>
      <LoadingSpin />
    </div>
  );
}
