/* eslint-disable react/prop-types */
import { ENDPOINT } from 'app/core/constants/endpoints';
import * as ROUTES from 'app/core/constants/routes';
import history from 'app/history';
import { createContext, useEffect, useState } from 'react';
import useAuth from './hooks/useAuth';

const Context = createContext();

function AuthProvider({ children }) {
  const { authenticated, loading, error, handleLogin } = useAuth();
  const [themes, setThemes] = useState([]);
  const [subthemes, setSubthemes] = useState([]);

  useEffect(() => {
    if (authenticated) {
      getThemes();
      getSubthemes();
    }
  }, [authenticated]);

  async function getThemes() {
    await ENDPOINT.FAQ.GET_ALL_THEMES()
      .then((v) => setThemes(v.data))
      .catch(() => {
        history.push(ROUTES.ERROR);
      });
  }

  async function getSubthemes() {
    await ENDPOINT.FAQ.GET_ALL_SUBTHEMES()
      .then((v) => setSubthemes(v.data))
      .catch(() => {
        history.push(ROUTES.ERROR);
      });
  }

  return (
    <Context.Provider
      value={{ loading, error, authenticated, handleLogin, themes, subthemes }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
