export function debounce(func, wait, id, timers = {}) {
  clearTimeout(timers[id]);
  timers[id] = setTimeout(func, wait);
}

export const getDataStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const setDataStorage = (key, data) =>
  sessionStorage.setItem(key, JSON.stringify(data));

export const normalizeStringNFD = (str = '') =>
  str
    .toLocaleLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const setStyleById = (id, property) => {
  document.getElementById(id).style = `${property}`;
};
export const toKebabCase = (text) => {
  return text
    ?.toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};
