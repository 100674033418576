import api from 'app/api';
import { ENDPOINT } from 'app/core/constants/endpoints';
import * as ROUTES from 'app/core/constants/routes';
import history from 'app/history';
import { useEffect, useState } from 'react';
import { getDataStorage, setDataStorage } from 'app/shared/utils/common';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const token = getDataStorage('token');

    if (token) {
      setAuthorization(JSON.parse(token));
    }

    setLoading(false);
  }, []);

  async function handleLogin() {
    const res = await ENDPOINT.FAQ.GET_TOKEN({
      user: process.env.REACT_APP_USER,
      password: process.env.REACT_APP_PASSWORD,
    }).catch(() => setError(true));
    const token = res?.data?.jwt;

    if (token) {
      setDataStorage('token', JSON.stringify(token));

      setAuthorization(token);
      history.push(ROUTES.HOME);
    } else {
      history.push(ROUTES.ERROR);
    }
  }

  function setAuthorization(token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    setAuthenticated(true);
  }

  return { authenticated, loading, error, handleLogin };
}
