import styled from 'styled-components';

export const Container = styled.header`
  overflow: auto;

  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  margin: 0 1.5rem;

  header {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;
  }
`;

export const List = styled.div`
  margin-top: 34px;
`;

export const ButtonShowHideSubTheme = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background: none;
  width: 100%;
  font-size: 16px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  svg {
    color: ${({ theme }) => theme.colors.arrow};
  }
`;

export const Question = styled.div`
  a {
    color: ${({ theme }) => theme.colors.midEmphasis};
    background: none;
    display: block;
    text-align: left;
    font-size: 14px;
  }
`;

export const QuestionItem = styled.div`
  max-width: 100%;
  padding-top: 15px;
`;
