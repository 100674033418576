export default {
  colors: {
    background:
      'linear-gradient(140deg, rgb(31, 63, 94), rgb(4, 41, 74), rgb(4, 41, 74), rgb(3, 30, 58), rgb(3, 30, 58), rgb(3, 30, 58))',
    backgroundModal: 'rgb(31, 63, 94)',
    blue: 'rgb(137,172,198)',
    transparenceModal: 'rgba(0, 23, 44, 0.56)',
    white: 'rgb(255, 255, 255)',
    placeholder: 'rgb(255, 255, 255, .6)',
    neutral: '#5B788E',
    focusInput: 'linear-gradient(rgb(255, 85, 77), rgb(255, 186, 143))',
    midEmphasis: 'rgba(255, 255, 255, 0.6)',
    arrow: 'rgb(255, 182, 132)',
  },
};
