import React, { useState } from 'react';
import { Container } from './styles';
import Modal from '../Modal';

export default function ChatFooter() {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Container>
      <button onClick={() => setModalVisible(true)}>
        Ligue para o nosso atendimento ao cliente
      </button>
      {modalVisible ? <Modal onClose={() => setModalVisible(false)} /> : null}
    </Container>
  );
}
