import styled from 'styled-components';

export const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.transparenceModal};
`;

export const ModalBox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.backgroundModal};
  color: ${({ theme }) => theme.colors.white};
  padding: 32px 24px 46px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px 20px 0px 0px;

  h2 {
    margin-bottom: 24px;
  }

  p,
  a {
    color: ${({ theme }) => theme.colors.midEmphasis};
    text-align: center;
    font-size: 14px;
  }

  p {
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  }

  a {
    text-decoration: underline;
    margin-bottom: 28px;
    margin-top: 4px;
  }

  .color {
    color: ${({ theme }) => theme.colors.blue} !important;
    font-weight: 700;
  }

  button {
    margin-top: 1.2rem;
  }
`;

export const Align = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
`;

export const AlignColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AlignGap = styled(AlignColumn)`
  margin-bottom: 28px;

  span {
    color: ${({ theme }) => theme.colors.neutral};
    font-weight: 400;
    letter-spacing: 0.33px;
  }
`;
