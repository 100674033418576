import {
  MOTOROLA_WHATSSAP_NUMBER,
  REDIRECT_WHATSSAP_URL,
} from 'app/core/constants/common';
import PropTypes from 'prop-types';
import { Align, AlignColumn, AlignGap, Container, ModalBox } from './styles';

// eslint-disable-next-line react/prop-types
export default function Modal({ id = 'modal', onClose }) {
  Modal.propTypes = {
    onClose: PropTypes.func,
  };

  const handleOutsideClick = (e) => {
    if (e.target.id) onClose();
  };

  function launchDialer(phoneNumber) {
    window.Android.launchDialer(phoneNumber);
  }

  function conditionalEnviroment(phoneNumber) {
    const isAndroidBrowser =
      /Android/.test(window.navigator.userAgent) &&
      !/wv/.test(window.navigator.userAgent);
    const isAndroidWebview =
      /Android/.test(window.navigator.userAgent) &&
      /wv/.test(window.navigator.userAgent);

    if (isAndroidBrowser) {
      window.location.href = `tel:${phoneNumber}`;
    }

    if (isAndroidWebview) {
      launchDialer(phoneNumber);
    }
  }

  return (
    <Container id={id} onClick={handleOutsideClick}>
      <ModalBox>
        <h2>Atendimento ao Cliente</h2>
        <AlignGap>
          <p>
            Fale com o Atendimento ao cliente se precisar de ajuda individual
          </p>
          <span>
            De segunda a sexta, das 9h às 20h. Aos sábados, das 9h às 15h.
            Exceto Domingos e feriados.
          </span>
        </AlignGap>
        <Align>
          <span className="color">Mande um WhatsApp</span>

          <AlignColumn>
            <span>WhatsApp</span>
            <a href={REDIRECT_WHATSSAP_URL(MOTOROLA_WHATSSAP_NUMBER)}>
              55 (11) 5198 0000
            </a>
          </AlignColumn>
        </Align>

        <Align>
          <span className="color">ou ligue para nós</span>
          <AlignColumn>
            <span>Telefone</span>
            <a
              onClick={() => {
                conditionalEnviroment('080051980000');
              }}
            >
              0800 5198 0000
            </a>

            <button onClick={onClose}>Cancelar</button>
          </AlignColumn>
        </Align>
      </ModalBox>
    </Container>
  );
}
