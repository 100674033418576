import { DEFAULT_CONTENT_HEIGHT } from 'app/core/constants/common';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import Tariffs from '../Tariffs';
import { Answer, Container, ContainerAttachment } from './styles';

export default function ChatFooter() {
  const location = useLocation();
  const { faqData } = location.state;
  const { themeName } = location.state;
  const { question, answer, attachments } = faqData;
  const supportedImageType = ['jpeg', 'jpg', 'png', 'gif'];
  const supportedVideoType = ['mp4', 'mov', 'webm'];
  const fileType = attachments?.split('.')[1]?.split(']')[0];
  const url = attachments?.split('(')[1]?.split(')')[0];
  const isSupportedType = supportedImageType.some((type) => fileType === type);
  const isSupportedVideoType = supportedVideoType.some(
    (type) => fileType === type,
  );

  // TODO: Refactor attachments for v4
  const showAttachments = () => {
    if (attachments?.length > 0) {
      return (
        <>
          <ContainerAttachment>
            {isSupportedType ? (
              <ReactMarkdown>{attachments}</ReactMarkdown>
            ) : isSupportedVideoType ? (
              <video width="300" autoPlay>
                <source src={url} type={`video/${fileType}`} />
              </video>
            ) : (
              <p>{attachments}</p>
            )}
          </ContainerAttachment>
        </>
      );
    }
  };

  return (
    <Container style={DEFAULT_CONTENT_HEIGHT}>
      <h2>{question}</h2>
      {themeName === 'Tarifas' ? (
        <Tariffs />
      ) : (
        <Answer>
          <p>{answer}</p>
          <p>{showAttachments()}</p>
        </Answer>
      )}
    </Container>
  );
}
