/* eslint-disable react/prop-types */
import { getDataStorage } from 'app/shared/utils/common';
import { HEIGHT } from 'app/core/constants/common';
import * as ROUTES from 'app/core/constants/routes';
import AnswerPage from 'app/pages/Answer';
import BadRequestPage from 'app/pages/BadRequest';
import Home from 'app/pages/Home';
import LoginPage from 'app/pages/Login/index';
import SubThemesPage from 'app/pages/SubThemes';
import TariffsPage from 'app/pages/TariffsPage';
import LoadingSpin from 'app/shared/components/LoadinSpin';
import { Context } from 'context/AuthContext';
import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated, error } = useContext(Context);

  if (loading) {
    return (
      <div style={HEIGHT}>
        <LoadingSpin />
      </div>
    );
  }

  if (error) {
    return <Redirect to={ROUTES.ERROR} />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path={ROUTES.LOGIN} component={LoginPage} />
      <CustomRoute isPrivate exact path={ROUTES.HOME} component={Home} />
      <CustomRoute
        isPrivate
        path={ROUTES.SUB_THEMES}
        component={SubThemesPage}
      />
      <CustomRoute isPrivate path={ROUTES.ANSWER} component={AnswerPage} />
      <CustomRoute isPrivate path={ROUTES.TARIFFS} component={TariffsPage} />
      <CustomRoute path={ROUTES.ERROR} component={BadRequestPage} />
    </Switch>
  );
}
