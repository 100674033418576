import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  margin-top: 24px;

  img {
    width: 140px;
  }

  div {
    max-width: 92%;
  }

  h4 {
    font-size: 14pt;
    font-weight: 700;
    margin: 24px 0;
    color: ${({ theme }) => theme.colors.white};
  }

  section {
    display: flex;
    justify-content: space-between;
  }

  p {
    font-size: 11pt;
    color: ${({ theme }) => theme.colors.white};

    :last-child {
      white-space: nowrap;
    }
  }
`;
