import api from 'app/api';
import axios from 'axios';
import useAxios from 'context/hooks/useAxios';
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ENDPOINT = {
  FAQ: {
    GET_ALL_ANSWERS: (query) => useAxios(`${BASE_URL}/faqs`, query),
    GET_ALL_THEMES: () => api.get(`${BASE_URL}/themes`),
    GET_ALL_SUBTHEMES: () => api.get(`${BASE_URL}/subthemes`),
    GET_ONE_ANSWER: (answerId) => useAxios(`${BASE_URL}/faqs/${answerId}`),
    GET_ONE_THEME: (themeId) =>
      useAxios(`${BASE_URL}/sub-themes/findByThemeId/${themeId}`),
    GET_TOKEN: (auth) =>
      axios.post(`${BASE_URL}/auth/local`, {
        identifier: auth.user,
        password: auth.password,
      }),
  },
};
