export const COLOR_ERROR = {
  color: 'white',
};

export const DEFAULT_CONTENT_HEIGHT = {
  height: `${window.innerHeight - 185}px`,
};

export const HEIGHT = {
  height: `${window.innerHeight}px`,
};
export const WIDTH = {
  width: `${window.innerWidth}px`,
};

export const CENTER_WIDTH = `${window.innerWidth / 2 - 35}px`;
export const CENTER_HEIGHT = `${window.innerHeight / 2}px`;

export const REDIRECT_WHATSSAP_URL = (number) =>
  `http://api.whatsapp.com/send?1=pt_BR&phone=${number}`;

export const MOTOROLA_WHATSSAP_NUMBER = 551151980000;
