import { DEFAULT_CONTENT_HEIGHT, HEIGHT } from 'app/core/constants/common';
import * as ROUTES from 'app/core/constants/routes';
import history from 'app/history';
import { Answer } from 'app/shared/components/Answer/styles';
import {
  normalizeStringNFD,
  setDataStorage,
  setStyleById,
  toKebabCase,
} from 'app/shared/utils/common';
import { Context } from 'context/AuthContext';
import useThemeParams from 'context/hooks/useParams.js';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import {
  Container,
  InputSearchContainer,
  List,
  RenderThemesOrAnswers,
  Search,
  SearchIcon,
} from './styles';

export default function Home() {
  const [search, setSearch] = useState(true);
  const [query, setQuery] = useState('');
  const [faqsForFilter, setFaqsForFilter] = useState(null);
  const [height, setHeight] = useState(DEFAULT_CONTENT_HEIGHT);
  const { themes, subthemes } = useContext(Context);
  const { themeType } = useThemeParams();

  function setDefaultTheme() {
    const themeFound = themes.find(
      (theme) => toKebabCase(normalizeStringNFD(theme?.name)) === themeType,
    );
    themeFound &&
      history.push(ROUTES.SUB_THEMES, {
        themeId: themeFound?.id,
        allData: themeFound,
      });
    setDataStorage('theme', null);
  }

  function setHeightValue(searchValue, filter) {
    const appContainerId = 'container-app';
    if (searchValue?.length > 0 && filter?.length > 2) {
      setHeight({});
      setStyleById(appContainerId, 'height: unset !important');
    } else if (searchValue?.length < 1 || filter?.length < 3) {
      setHeight(DEFAULT_CONTENT_HEIGHT);
      setStyleById(appContainerId, `height: ${HEIGHT.height} !important`);
    }
  }

  function renderThemesOrAnswers() {
    if (faqsForFilter) {
      if (faqsForFilter.length === 0) {
        return (
          <p>
            <br></br>
            <br></br>
            Não há resultados para sua busca.
          </p>
        );
      } else {
        return faqsForFilter.map((faq) => (
          <div key={faq?.id}>
            <List>
              <h2>{faq?.question}</h2>
              <Answer>
                <p>{faq?.answer}</p>
              </Answer>
            </List>
          </div>
        ));
      }
    } else {
      return themes.map((theme) => (
        <div key={theme?.id}>
          <List>
            <Link
              to={{
                pathname: ROUTES.SUB_THEMES,
                state: {
                  themeId: theme?.id,
                  allData: theme,
                },
              }}
            >
              {theme?.name}
            </Link>
          </List>
        </div>
      ));
    }
  }

  function compare(word, data) {
    const stringNFD = normalizeStringNFD(word);

    return normalizeStringNFD(data).includes(stringNFD);
  }

  function handleSearch(searchValue) {
    searchValue = searchValue?.trim();

    setQuery(searchValue);
    setSearch(true);

    const results = subthemes.reduce((acc, curr) => {
      return acc.concat(
        curr.faqs.filter(
          (faq) =>
            compare(searchValue, faq.question) ||
            compare(searchValue, faq.answer),
        ),
      );
    }, []);

    if (!searchValue) {
      setFaqsForFilter(null);
      setSearch(false);
    }

    setFaqsForFilter(results);
    setHeightValue(searchValue, results);
  }
  useEffect(() => {
    setDefaultTheme();
  });

  return (
    <Container style={height}>
      <Search>
        <h2>Perguntas Frequentes</h2>
        <InputSearchContainer>
          <SearchIcon>
            <AiOutlineSearch></AiOutlineSearch>
          </SearchIcon>
          <input
            placeholder="Procurar"
            type="search"
            onClick={() => !query && setSearch(false)}
            onBlur={() => {
              if (!query) {
                setFaqsForFilter(null);
              }
              setSearch(true);
            }}
            onChange={(event) => handleSearch(event.target.value)}
          />
        </InputSearchContainer>
      </Search>
      <RenderThemesOrAnswers>
        {search && renderThemesOrAnswers()}
      </RenderThemesOrAnswers>
    </Container>
  );
}
