import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  margin: 0 1.5rem;

  p {
    color: ${({ theme }) => theme.colors.midEmphasis};
    white-space: pre-wrap;
  }
`;

export const List = styled.div`
  margin-top: 34px;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const InputSearchContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    width: 87%;
    background: none;
    border-bottom: 1px solid;
    color: ${({ theme }) => theme.colors.white};
    height: 36px;
    font-family: Roboto;
    padding: 0 0 0 32px;
    font-size: 16px;

    ::-webkit-search-cancel-button {
      /* Remove default */
      -webkit-appearance: none;
      height: 1em;
      width: 1em;
      border-radius: 50em;
      background: url('data:image/svg+xml,%3Csvg%20width%3D%22142%22%20height%3D%22143%22%20viewBox%3D%220%200%20142%20143%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M61.0352%2081.4126L81.0002%2061.5525%22%20stroke%3D%22white%22%20stroke-width%3D%22171%22%2F%3E%0A%3Cpath%20d%3D%22M61.0001%2061.5526L80.9651%2081.4827%22%20stroke%3D%22white%22%20stroke-width%3D%22171%22%2F%3E%0A%3C%2Fsvg%3E%0A');
      background-repeat: no-repeat;
      background-size: 1rem;
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.placeholder};
      font-family: Roboto;
      font-size: 16px;
    }
  }
`;
export const SearchIcon = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
`;

export const Search = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colors.background} fixed;
`;

export const RenderThemesOrAnswers = styled.div`
  padding-top: 86px;
`;
