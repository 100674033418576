import history from 'app/history';
import { ImArrowLeft2 } from 'react-icons/im';
import { useLocation } from 'react-router-dom';
import { Container } from './styles';

export default function Header() {
  const location = useLocation();
  const pathname = location.pathname;
  const pathsHiddeBackButton = ['/login', '/'];
  const checkTohiddeBackButton = pathsHiddeBackButton.every(
    (path) => path !== pathname,
  );

  return (
    <Container>
      {checkTohiddeBackButton && (
        <ImArrowLeft2 size="18" color="#fff" onClick={() => history.goBack()} />
      )}
      <span>Ajuda</span>
    </Container>
  );
}
