import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  margin: 0 1.5rem;
`;

export const Answer = styled.div`
  margin-top: 24px;
  max-width: 340px;

  p {
    color: ${({ theme }) => theme.colors.midEmphasis};
    text-align: left;
    font-size: 14px;
    line-height: 1.8;
    font-family: Roboto;
    white-space: pre-wrap;
  }
`;

export const ContainerAttachment = styled.div`
  img {
    width: 100%;
    margin-top: 5%;
  }
`
