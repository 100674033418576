import { DEFAULT_CONTENT_HEIGHT } from 'app/core/constants/common';
import * as ROUTES from 'app/core/constants/routes';
import NotFound from 'app/shared/components/NotFound';
import { useState } from 'react';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import {
  ButtonShowHideSubTheme,
  Container,
  List,
  Question,
  QuestionItem,
} from './styles';

export default function Header() {
  const location = useLocation();
  const { allData } = location.state;
  const { subthemes } = allData;
  const headerName = allData.name;
  const [show, setShow] = useState(subthemes);

  const showOnClick = (index) => {
    const filterDataShow = subthemes.map((v, i) => {
      const collapsed = (open) => ({ ...v, show: open });
      if (show[index]?.show) {
        return collapsed(false);
      }

      return i === index ? collapsed(true) : collapsed(false);
    });

    setShow(filterDataShow);
  };

  const showQuestions = (faq) => {
    if (faq.question) {
      return (
        <QuestionItem key={faq.id}>
          <Link
            to={{
              pathname: ROUTES.ANSWER,
              state: {
                answerId: faq.id,
                faqData: faq,
                themeName: headerName,
              },
            }}
          >
            {faq.question}
          </Link>
        </QuestionItem>
      );
    } else {
      return <NotFound />;
    }
  };

  return (
    <Container style={DEFAULT_CONTENT_HEIGHT}>
      <header>
        <h2>{headerName}</h2>
      </header>

      {subthemes.map((subtheme, index) => (
        <div key={subtheme.id}>
          <List>
            <ButtonShowHideSubTheme onClick={() => showOnClick(index)}>
              <div>
                {subtheme.name}
                {show[index]?.show ? (
                  <RiArrowDownSLine />
                ) : (
                  <RiArrowRightSLine />
                )}
              </div>
            </ButtonShowHideSubTheme>
            {show[index]?.show ? (
              <Question>
                {show[index].faqs.map((faq) => showQuestions(faq)).length > 0
                  ? show[index].faqs.map((faq) => showQuestions(faq))
                  : showQuestions(false)}
              </Question>
            ) : null}
          </List>
        </div>
      ))}
    </Container>
  );
}
